export const defaultFilter = {
  nameChecked: false,
  name: '',
  managerCodeChecked: false,
  managerCode: '',
  managerNameChecked: false,
  managerName: '',
  channelChecked: false,
  channel: {
    directChecked: false,
    referralChecked: false,
  },
  workProvince: '',
  createdAtChecked: false,
  createdAt: '',
  dateChecked: false,
  date: '',
  prospectStatusChecked: false,
  prospectStatus: {
    waitingForReAssignChecked: false,
    submittiedShortFormChecked: false,
    managerAcceptedInterviewChecked: false,
    submittiedShortFormInactiveChecked: false,
    droppedChecked: false,
    changedManagerChecked: false,
    declinedInterviewChecked: false,
    passedInterviewChecked: false,
    failedInterviewChecked: false,
    failedOutOfLimitChecked: false,
    timeoutChecked: false,
    savedFullFormChecked: false,
    submittedFullFormChecked: false,
    reviewedChecked: false,
    acceptedReviewChecked: false,
    requestedMemoChecked: false,
    savedFullFormMemoChecked: false,
    submittedFullFormMemoChecked: false,
    passedProspectHistoryChecked: false,
    requestedDecisionChecked: false,
    requestedDecisionCcmChecked: false,
    updatedDecisionChecked: false,
    approvedToBeAgentChecked: false,
    droppedAgentChecked: false,
    updatedELicencingChecked: false,
    updatedAgentCodeChecked: false,
    reviewedMemoChecked: false,
    requestedELicencingChecked: false,
    updatedELicencingPassedChecked: false,
    updatedELicencingFailedChecked: false,
    requestedRcmsChecked: false,
    sentMailDecisionCcmChecked: false,
    updatedDecisionPassedChecked: false,
    updatedDecisionCompletedChecked: false,
    updatedDecisionFailedChecked: false,
  },
  loginStatusChecked: false,
  loginStatus: {
    statusActiveChecked: false,
    statusInactiveChecked: false,
  },
  userStatusChecked: false,
  userStatus: {
    statusActiveChecked: false,
    statusInactiveChecked: false,
    statusDeletedChecked: false,
  },
}
