import useTheme from '@mui/material/styles/useTheme'
import Divider from '@mui/material/Divider'
// components
import { CustomInput } from '../../../../../../components/CRUD/components/CustomInput'
import CustomerSwitchInLine from '../../../../../../components/CRUD/components/CustomSwitchInLine'
import CustomDropdown from '../../../../../../components/Input/Dropdown'
// redux
import { useSelector } from '../../../../../../redux/store'
import { selectCrudFormData } from '../../../../../../redux/selector'
//
import { sectionNameOptions, topicOptions } from '../model/options'
import CustomSwitchInLine from '../../../../../../components/CRUD/components/CustomSwitchInLine'

export default function BasicInfo() {
  const theme = useTheme()

  const formData = useSelector(selectCrudFormData)

  return (
    <>
      <CustomInput
        required
        labelName="เหตุผล"
        fieldName="text"
        placeholder="ระบุเหตุผล"
      />

      <CustomDropdown
        value={formData.sectionID ?? null}
        required
        fullWidth
        label="รายการขั้นตอนสำหรับตรวจสอบใบสมัคร"
        placeholder="ระบุรายการขั้นตอนสำหรับตรวจสอบใบสมัคร"
        crud="sectionID"
        options={sectionNameOptions}
        getOptionLabel={(option) =>
          option.label ??
          sectionNameOptions.find((item) => item.value === option)?.label
        }
        boxSx={{ marginBottom: 0 }}
        sx={{
          '& .MuiOutlinedInput-root': {
            padding: theme.spacing(0.5, 1),
            borderRadius: '4px',
          },
        }}
      />
      <CustomDropdown
        value={formData.topic ?? null}
        // required
        fullWidth
        label="Topic"
        placeholder="Topic"
        crud="topic"
        options={topicOptions}
        getOptionLabel={(option) =>
          option.label ??
          topicOptions.find((item) => item.value === option)?.label
        }
        boxSx={{ marginBottom: 0 }}
        sx={{
          '& .MuiOutlinedInput-root': {
            padding: theme.spacing(0.5, 1),
            borderRadius: '4px',
          },
        }}
      />

      <CustomInput
        required
        labelName="ข้อความสำหรับผู้จัดการ"
        fieldName="reasonForMA"
        placeholder="ระบุข้อความสำหรับผู้จัดการ"
      />

      <CustomInput
        required
        labelName="ข้อความสำหรับผู้สมัคร"
        fieldName="reasonForProspect"
        placeholder="ระบุข้อความสำหรับผู้สมัคร"
      />
      <CustomSwitchInLine
        fieldName="requireDetail"
        title="ต้องระบุรายละเอียดเพิ่มเติม (กรณีเหตุผลอื่นๆ)"
      />
      <CustomerSwitchInLine fieldName="status" title="สถานะ" />

      <Divider />
    </>
  )
}
