import React from 'react'
import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'
import { handleResetTable } from './events'
import { handleFilterTotal, type Filter } from './handleFilterTotal'
import type {
  TypeAppState,
  InitialTablePayload,
} from './types/initialStateTable'
import localforage from 'localforage'
import type { Path } from 'history'
import { type UseHistory } from '../../../utils/useHistory'

const initialState: TypeAppState = {
  search: { placeholder: '', searchText: '' },
  table: {
    isLoading: false,
    isCheckBox: false,
    isSelectAll: false,
    rows: [],
    headCells: [],
    allCount: 0,
    defaultPage: -1,
    page: -1,
    tablePage: 0,
    selected: [],
    numSelected: 0,
    order: 'desc',
    sort: 'updatedAt',
    defaultSortId: 'updatedAt',
    defaultSort: { state: 0, id: '', active: false },
    limit: '100',
    defaultRowsPerPage: { label: '5', value: 5 },
    rowsPerPage: { label: '100', value: 100 },
    isShowPagination: true,
  },
  filter: {
    isFilterDrawer: false,
    status: [],
    filterProp: null,
    filterTotal: 0,
    quickFilter: [],
    other: null,
  },
  handleSearch: null,
  onDownload: null,
  onDelete: null,
  onQuickFilter: null,
  onChangeStatus: null,
  customStyle: {},
  customToolbar: null,
  subActions: null,
  optionDownload: null,
  data: [],
  filterState: null,
  filterStateMemo: null,
  handleDateSearch: null,
  onEligibleExam: null,
  moreMenu: [],
}

const tableSlice = createSlice({
  name: 'table',
  initialState,
  reducers: {
    startLoading: (state) => {
      state.table.isLoading = true
    },
    stopLoading: (state) => {
      state.table.isLoading = false
    },
    setData: (state, action) => {
      state.data = action.payload
    },
    setInitialTable: (
      state: TypeAppState,
      { payload }: PayloadAction<InitialTablePayload>
    ) => {
      state.table.isCheckBox = _.get(payload, 'isCheckBox', true)
      state.table.rows = _.get(payload, 'rows', [])
      state.table.allCount = _.get(payload, 'allCount', 0)
      state.table.headCells = _.get(payload, 'headCells', [])
      state.filter.status = _.get(payload, 'status', [])
      state.filter.quickFilter = _.get(payload, 'quickFilter', [])
      state.search.placeholder = _.get(payload, 'placeholder', '')
      state.handleSearch = _.get(payload, 'handleSearch', null)
      state.onDelete = _.get(payload, 'onDelete', null)
      state.onDownload = _.get(payload, 'onDownload', null)
      state.onQuickFilter = _.get(payload, 'onQuickFilter', null)
      state.onChangeStatus = _.get(payload, 'onChangeStatus', null)
      state.customStyle = _.get(payload, 'customStyle', undefined)
      state.table.isShowPagination = _.get(payload, 'isShowPagination', true)
      state.table.sort = payload.sort ?? state.table.sort
      state.moreMenu = []
    },
    setChangeRowsPerPage: (
      state,
      {
        payload,
      }: PayloadAction<Pick<TypeAppState['table'], 'rowsPerPage' | 'limit'>>
    ) => {
      state.table.defaultRowsPerPage = { label: '5', value: 5 }
      state.table.rowsPerPage = payload.rowsPerPage
      state.table.limit = payload.limit
      state.table.tablePage = 0
      state.table.page = 1
    },
    setChangePage: (
      state,
      {
        payload,
      }: PayloadAction<Pick<TypeAppState['table'], 'page' | 'tablePage'>>
    ) => {
      state.table.defaultPage = _.get(state, 'table.page', 1)
      state.table.page = payload.page
      state.table.tablePage = payload.tablePage
    },
    setSearchText: (
      state,
      {
        payload,
      }: PayloadAction<Pick<TypeAppState['search'], 'searchText'>['searchText']>
    ) => {
      state.search.searchText = payload
    },
    setSearch: (state, { payload }) => {
      state.search.searchText = payload
    },
    setSearchPlaceholder: (state, { payload }) => {
      state.search.placeholder = payload
    },
    handleSearch: (state, { payload }) => {
      state.search.searchText = payload
    },
    setStatusFilter: (
      state,
      {
        payload,
      }: PayloadAction<Pick<TypeAppState['filter'], 'status'>['status']>
    ) => {
      state.filter.status = payload
    },
    setFilterProp: (
      state,
      {
        payload,
      }: PayloadAction<Pick<TypeAppState['filter'], 'filterProp'>['filterProp']>
    ) => {
      state.filter.filterProp = payload
    },
    resetTable: (state) => {
      handleResetTable(state)
    },
    setToggleFilterDrawer: (
      state,
      {
        payload,
      }: PayloadAction<
        Pick<TypeAppState['filter'], 'isFilterDrawer'>['isFilterDrawer']
      >
    ) => {
      state.filter.isFilterDrawer = payload
    },
    setHandleChange: <T>(
      state: TypeAppState,
      { payload }: PayloadAction<{ key: string; value: T }>
    ) => {
      const { key, value } = payload
      _.set(state, key, value)
    },
    setFilterTotal: (state, { payload }: PayloadAction<Filter | number>) => {
      state.filter.filterTotal =
        typeof payload === 'number' ? payload : handleFilterTotal(payload)
    },
    clearFilter: (state) => {
      state.filterState = null
      state.filterStateMemo = null
      state.filter.filterTotal = 0
      state.filter.filterProp = null
    },
    resetTablePage: (state) => {
      state.table.tablePage = 0
    },
    resetPage: (state) => {
      state.table.page = 1
    },
    setDefaultSort: (
      state,
      { payload }: PayloadAction<TypeAppState['table']['defaultSort']>
    ) => {
      state.table.defaultSort = payload
    },
    setOrder: (state, { payload }) => {
      state.table.order = payload.order
      state.table.sort = payload.sort
    },
    setSelected: (
      state,
      action: PayloadAction<Pick<TypeAppState['table'], 'selected'>['selected']>
    ) => {
      state.table.selected = action.payload
    },
    setOnView: (
      state,
      {
        payload,
      }: PayloadAction<{
        history: UseHistory
        viewUrl: string | Path
      }>
    ) => {
      const { history, viewUrl } = payload
      handleResetTable(state)
      history.push(viewUrl)
    },
    setOnEdit: (
      _,
      {
        payload,
      }: PayloadAction<{ history: UseHistory; editUrl: string | Path }>
    ) => {
      localforage.removeItem('persist:root')
      const { history, editUrl } = payload
      history.push(editUrl)
    },
    setCustomToolbar: (
      state,
      { payload }: PayloadAction<{ customToolbar: React.ReactNode }>
    ) => {
      state.customToolbar = payload.customToolbar
    },
    setSubActions: (state, { payload }: PayloadAction<React.ReactNode>) => {
      state.subActions = payload
    },
    setAddOptionDownload: (
      state,
      { payload }: PayloadAction<React.ReactNode>
    ) => {
      state.optionDownload = payload
    },
    setMoreMenu: (state, { payload }) => {
      state.moreMenu = payload
    },
    setDownload: (state, { payload }) => {
      state.onDownload = payload
    },
    setSelectAll: (state, { payload }) => {
      state.table.isSelectAll = payload
    },
  },
})

export const {
  startLoading,
  stopLoading,
  setData,
  setInitialTable,
  resetTable,
  setChangeRowsPerPage,
  setChangePage,
  setToggleFilterDrawer,
  setHandleChange,
  setSearchText,
  setStatusFilter,
  setFilterProp,
  setFilterTotal,
  clearFilter,
  resetTablePage,
  resetPage,
  setDefaultSort,
  setOrder,
  setSelected,
  setOnView,
  setOnEdit,
  setSearch,
  setSearchPlaceholder,
  setAddOptionDownload,
  handleSearch,
  setCustomToolbar,
  setSubActions,
  setMoreMenu,
  setDownload,
  setSelectAll,
} = tableSlice.actions

export default tableSlice.reducer
