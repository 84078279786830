export const STATUS = {
  WAITING_FOR_REASSIGN_MANAGER: 'DL_SUCCESSFULLY_SENT_ASSIGN_RULE', // รอ Assign
  SUBMITTED_SHORT_FORM: 'DL_ASSIGNED_MA', // รอนัดหมาย
  MANAGER_ACCEPTED_INTERVIEW: 'I_ACCEPTED_INTERVIEW', // รอนัดสัมภาษณ์
  SUBMITTED_SHORT_FORM_INACTIVE_ACC: 'DL_SUBMITTED_SHORT_FORM', // รอการยืนยันตัวตน
  DROPPED: 'DL_DROPPED', // ไม่ผ่านการเป็นตัวแทน
  // CHANGED_MANAGER: 'DL_CHANGED_MA', // ถูกมอบหมายผู้จัดการคนใหม่
  DECLINED_INTERVIEW: 'I_DECLINED_INTERVIEW', // โดนปฎิเสธการนัดสัมภาษณ์
  PASSED_INTERVIEW: 'I_PASSED_INTERVIEW', // ผ่านการสัมภาษณ์
  FAILED_INTERVIEW: 'I_FAILED_INTERVIEW', // ไม่ผ่านการสัมภาษณ์
  FAILED_OUT_OF_LIMIT: 'I_FAILED_OUT_OF_LIMIT', // ไม่ผ่านการสัมภาษณ์เกินกำหนด
  TIME_OUT_OF_LIMIT: 'I_TIME_OUT_OF_LIMIT', // ไม่มีการดำเนินงานจากผู้จัดการ
  SAVED_FULL_FORM: 'DS_SAVED_FULL_FORM', // เริ่มกรอกฟอร์มแบบเต็ม
  SUBMITTED_FULL_FORM: 'DS_SUBMITTED_FULL_FORM', // ส่งฟอร์มแบบเต็มสำเร็จ
  REVIEWED: 'DS_REVIEWED', // ผู้จัดการตรวจสอบแล้ว
  ACCEPTED_REVIEW: 'DCV_ACCEPTED_REVIEW', // DC เริ่มตรวจสอบแล้ว
  REQUESTED_MEMO: 'DCV_REQUESTED_MEMO', // DC ต้องการข้อมูลเพิ่มเติ่ม
  SAVED_FULL_FORM_MEMO: 'DS_SAVED_FULL_FORM_MEMO', // เริ่มแก้ไขฟอร์มแบบเต็ม
  SUBMITTED_FULL_FORM_MEMO: 'DS_SUBMITTED_FULL_FORM_MEMO', // ส่งฟอร์มแบบเต็มเพิ่มเติมสำเร็จ
  PASSED_PROSPECT_HISTORY: 'DCV_PASSED_PROSPECT_HISTORY', // DC ตรวจเอกสารผ่านแล้ว
  REQUESTED_DECISION: 'DCV_REQUESTED_DESCISION', // DC ต้องการตัดสินใจเพิ่มเติม
  REQUESTED_DECISION_CCM: 'DCV_REQUESTED_DESCISION_CCM', // รอพิจารณา CCM
  UPDATED_DECISION: 'DCV_UPDATED_DESCISION', // ได้รับผลการตัดสินใจแล้ว
  APPROVED_TO_BE_AGENT: 'DCV_APPROVED_TO_BE_AGENT', // DC อนุมัติการเป็นตัวแทน
  DROPPED_AGENT: 'DCV_DROPPED', // DC ไม่อนุมัติการเป็นตัวแทน
  // UPDATED_E_LICENCING: 'DCV_UPDATED_E_LICENCING', // DC อัพเดทผล OIC แล้ว
  UPDATED_AGENT_CODE: 'DCV_UPDATED_AGENT_CODE', // เป็นตัวแทนเรียบร้อย
  REVIEWED_MEMO: 'DS_REVIEWED_MEMO', // กำลังตรวจสอบใบสมัคร/ประวัติ
  REQUESTED_E_LICENCING: 'DCV_REQUESTED_E_LICENCING', // รอเลขใบอนุญาต
  UPDATED_E_LICENCING_PASSED: 'DCV_UPDATED_E_LICENCING_PASSED', // ได้รับเลขใบอนุญาต
  UPDATED_E_LICENCING_FAILED: 'DCV_UPDATED_E_LICENCING_FAILED', // ไม่ผ่านการออกใบอนุญาต คปภ
  REQUESTED_RCMS: 'DCV_REQUESTED_RCMS', // รอรหัสตัวแทน
  SENT_MAIL_DECISION_CCM: 'DCV_SENT_MAIL_DESCISION_CCM', // รอ CCM ตรวจสอบ
  // UPDATED_DECISION_PASSED: 'DCV_UPDATED_DESCISION_PASSED', // ผ่านพิจารณา
  // UPDATED_DECISION_COMPLETED: 'DCV_UPDATED_DESCISION_COMPLETED', // ได้รับผลพิจารณา
  // UPDATED_DECISION_FAILED: 'DCV_UPDATED_DESCISION_FAILED', // ไม่ผ่านพิจารณา
}

export const LOGIN_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
}

export const USER_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  DELETED: 'DELETED',
}

export const CHANNEL = {
  DIRECT: 'DIRECT',
  REFERRAL: 'REFERRAL',
}
