import _ from 'lodash'
import { enumStatus } from '../../../../../../components/CRUD/enum/status'
import { sendEvent } from '../../../../../../components/CRUD/enum/sendEvent'
import dayjs from 'dayjs'

export const postBodyMutation = (data: any) => {
  const status = _.get(data, 'status', enumStatus.INACTIVE)
  const send = _.get(data, 'send', sendEvent.INACTIVE)
  const startDate = formatDate(_.get(data, 'startDate', null))
  const endDate = formatDate(_.get(data, 'endDate', null))
  const renewDate = formatDate(_.get(data, 'renewDate', null))
  const requireDetail = _.get(data, 'requireDetail', sendEvent.INACTIVE)

  return {
    uuid: _.get(data, 'uuid', undefined),
    id: _.get(data, 'id', ''),
    name: _.get(data, 'name', ''),
    send: send && send !== '' ? send : enumStatus.INACTIVE,
    requireDetail:
      requireDetail && requireDetail !== ''
        ? requireDetail
        : enumStatus.INACTIVE,
    status: status && status !== '' ? status : enumStatus.INACTIVE,
    startDate: startDate,
    endDate: endDate,
    renewDate: renewDate,
  }
}

const formatDate = (value: any) => {
  const format = 'MM/DD/YYYY'
  let dateValue = value
  if (dateValue && dateValue !== '')
    dateValue = dayjs(new Date(dateValue)).format(format)
  else dateValue = null
  return dateValue
}
