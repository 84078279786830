import { Box, Typography } from '@mui/material'
import { useSelector, shallowEqual } from 'react-redux'
import { CustomCard } from '../../../../../../components/Layout/CustomCard'
import { SliceModel } from '../../../../../../redux/models'
import { themeColor } from '../../../../../../styles/themes/themeData'
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded'
import { formatAgentCode } from '../../../../../../utils/formatAgentCode'
export default function Complete() {
  const { isLoading, agentCode } = useSelector(
    (state: SliceModel) => ({
      isLoading: state.prospect.detail.isLoading,
      agentCode: state.prospect.detail.fullForm?.main?.agentCode,
    }),
    shallowEqual
  )

  return (
    <CustomCard
      isLoading={isLoading}
      sx={{ height: '145px', alignItems: 'center', display: 'flex' }}
    >
      <Box display={'flex'} alignItems={'center'} gap={2}>
        <Box>
          <CheckCircleRoundedIcon
            sx={{
              fontSize: '60px',
              p: 1,
              backgroundColor: '#F5F5F5',
              borderRadius: 3,
              color: themeColor.success.main,
            }}
          />
        </Box>
        <Box>
          <Typography variant="body1b">ผ่านการสมัคร</Typography>
          <Typography variant="body1" color={'text.gray'}>
            รหัสตัวแทนที่ได้
          </Typography>
          <Typography variant="h6">{formatAgentCode(agentCode)}</Typography>
        </Box>
      </Box>
    </CustomCard>
  )
}
