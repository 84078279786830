import { store } from '../../../../../../redux/store'
import { getFormatDate } from '../../../../../../utils/getFormatDate'
import { formatDate } from '../constants/formatDate'
import { ProcessResultApprovalStatus } from '../../../../../../constants/status'
import { LOGIN_STATUS, USER_STATUS, CHANNEL } from '../model/status'

export function mutateFilterSateToProp() {
  const { filterState }: any = store.getState().table
  const status = []
  const loginStatus = []
  const userStatus = []
  const channel = []
  if (filterState?.prospectStatusChecked) {
    const statusMap = {
      waitingForReAssignChecked:
        ProcessResultApprovalStatus.DL_SUCCESSFULLY_SENT_ASSIGN_RULE,
      submittiedShortFormChecked: ProcessResultApprovalStatus.DL_ASSIGNED_MA,
      submittiedShortFormInactiveChecked:
        ProcessResultApprovalStatus.DL_SUBMITTED_SHORT_FORM,
      managerAcceptedInterviewChecked:
        ProcessResultApprovalStatus.I_ACCEPTED_INTERVIEW,
      droppedChecked: ProcessResultApprovalStatus.DL_DROPPED,
      // changedManagerChecked: ProcessResultApprovalStatus.DL_CHANGED,
      declinedInterviewChecked:
        ProcessResultApprovalStatus.I_DECLINED_INTERVIEW,
      passedInterviewChecked: ProcessResultApprovalStatus.I_PASSED_INTERVIEW,
      failedInterviewChecked: ProcessResultApprovalStatus.I_FAILED_INTERVIEW,
      failedOutOfLimitChecked:
        ProcessResultApprovalStatus.I_FAILED_OUT_OF_LIMIT,
      timeoutChecked: ProcessResultApprovalStatus.I_TIME_OUT_OF_LIMIT,
      savedFullFormChecked: ProcessResultApprovalStatus.DS_SAVED_FULL_FORM,
      submittedFullFormChecked:
        ProcessResultApprovalStatus.DS_SUBMITTED_FULL_FORM,
      reviewedChecked: ProcessResultApprovalStatus.DS_REVIEWED,
      dcAcceptedReviewChecked: ProcessResultApprovalStatus.DCV_ACCEPTED_REVIEW,
      dcRequestedMemoChecked: ProcessResultApprovalStatus.DCV_REQUESTED_MEMO,
      // savedFullFormMemoChecked:
      //   ProcessResultApprovalStatus.DS_SAVED_FULL_FORM_MEMO,
      submittedFullFormMemoChecked:
        ProcessResultApprovalStatus.DS_SUBMITTED_FULL_FORM_MEMO,
      // dcPassedProspectHistoryChecked:
      //   ProcessResultApprovalStatus.DCV_PASSED_PROSPECT_HISTORY,
      dcRequestedDecisionChecked:
        ProcessResultApprovalStatus.DCV_REQUESTED_DESCISION,
      dcRequestedDecisionCcmChecked:
        ProcessResultApprovalStatus.DCV_REQUESTED_DESCISION_CCM,
      dcUpdatedDecisionChecked:
        ProcessResultApprovalStatus.DCV_UPDATED_DESCISION,
      // dcApprovedToBeAgentChecked:
      //   ProcessResultApprovalStatus.DCV_APPROVED_TO_BE_AGENT,
      dcDroppedChecked: ProcessResultApprovalStatus.DCV_DROPPED,
      // dcUpdatedELicencingChecked:
      //   ProcessResultApprovalStatus.DCV_UPDATED_E_LICENCING,
      dcUpdatedAgentCodeChecked:
        ProcessResultApprovalStatus.DCV_UPDATED_AGENT_CODE,
      reviewedMemoChecked: ProcessResultApprovalStatus.DS_REVIEWED_MEMO,
      dcRequestedELicencingChecked:
        ProcessResultApprovalStatus.DCV_REQUESTED_E_LICENCING,
      dcUpdatedELicencingPassedChecked:
        ProcessResultApprovalStatus.DCV_UPDATED_E_LICENCING_PASSED,
      dcUpdatedELicencingFailedChecked:
        ProcessResultApprovalStatus.DCV_UPDATED_E_LICENCING_FAILED,
      dcRequestedRcmsChecked: ProcessResultApprovalStatus.DCV_REQUESTED_RCMS,
      dcSentMailDecisionCcmChecked:
        ProcessResultApprovalStatus.DCV_SENT_MAIL_DESCISION_CCM,
      // dcUpdatedDecisionPassedChecked:
      //   ProcessResultApprovalStatus.DCV_UPDATED_DESCISION_PASSED,
      // dcUpdatedDecisionCompletedChecked:
      //   ProcessResultApprovalStatus.DCV_UPDATED_DESCISION_COMPLETED,
      // dcUpdatedDecisionFailedChecked:
      //   ProcessResultApprovalStatus.DCV_UPDATED_DESCISION_FAILED,
    }

    Object.keys(filterState.prospectStatus || {}).forEach((key) => {
      if (
        filterState.prospectStatus[key as keyof typeof statusMap] &&
        statusMap[key as keyof typeof statusMap]
      ) {
        status.push(statusMap[key as keyof typeof statusMap])
      }
    })

    if (status.length === 0) {
      status.push(...Object.values(statusMap))
    }

    if (status.length === 0) {
      status.push(...Object.values(statusMap))
    }
  } else {
    status.length = 0
  }

  if (filterState?.loginStatusChecked) {
    if (filterState?.loginStatus.statusActiveChecked) {
      loginStatus.push(LOGIN_STATUS.ACTIVE)
    }
    if (filterState?.loginStatus.statusInactiveChecked) {
      loginStatus.push(LOGIN_STATUS.INACTIVE)
    }
    if (loginStatus.length === 0) {
      loginStatus.push(LOGIN_STATUS.ACTIVE, LOGIN_STATUS.INACTIVE)
    }
  } else {
    loginStatus.length = 0
  }

  if (filterState?.userStatusChecked) {
    if (filterState?.userStatus.statusActiveChecked) {
      userStatus.push(USER_STATUS.ACTIVE)
    }
    if (filterState?.userStatus.statusInactiveChecked) {
      userStatus.push(USER_STATUS.INACTIVE)
    }
    if (filterState?.userStatus.statusDeletedChecked) {
      userStatus.push(USER_STATUS.DELETED)
    }
    if (userStatus.length === 0) {
      userStatus.push(
        USER_STATUS.ACTIVE,
        USER_STATUS.INACTIVE,
        USER_STATUS.DELETED
      )
    }
  } else {
    userStatus.length = 0
  }

  if (filterState?.channelChecked) {
    if (filterState?.channel.directChecked) {
      channel.push(CHANNEL.DIRECT)
    }
    if (filterState?.channel.referralChecked) {
      channel.push(CHANNEL.REFERRAL)
    }
    if (channel.length === 0) {
      channel.push(CHANNEL.DIRECT, CHANNEL.REFERRAL)
    }
  }

  return {
    name: filterState.nameChecked ? filterState.name : '',
    managerCode: filterState.managerCodeChecked ? filterState.managerCode : '',
    managerName: filterState.managerNameChecked ? filterState.managerName : '',
    channel,
    workProvince: filterState.workProvinceChecked
      ? filterState.workProvince
      : '',
    startDate: filterState.dateChecked
      ? getFormatDate(filterState.startDate, formatDate)
      : '',
    endDate: filterState.dateChecked
      ? getFormatDate(filterState.endDate, formatDate)
      : '',
    status: status,
    loginStatus,
    userStatus,
  }
}
