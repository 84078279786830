import { CustomInput } from '../../../../../../components/CRUD/components/CustomInput'
import CustomSwitchInLine from '../../../../../../components/CRUD/components/CustomSwitchInLine'
import Divider from '@mui/material/Divider'

export const BasicInfo = () => {
  return (
    <>
      <CustomInput
        required
        labelName="เหตุผลการละทิ้ง"
        fieldName="name"
        placeholder="ระบุเหตุผลการละทิ้ง"
      />
      <CustomSwitchInLine
        fieldName="requireDetail"
        title="ต้องระบุรายละเอียดเพิ่มเติม (กรณีเหตุผลอื่นๆ)"
      />
      <CustomSwitchInLine
        fieldName="dropFromSystem"
        title="สถานะการละทิ้งโดยระบบ"
      />
      <CustomSwitchInLine fieldName="status" title="สถานะ" />
      <Divider />
    </>
  )
}
