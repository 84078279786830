import * as yup from 'yup'

export const formValidationSchema = yup.object({
  text: yup
    .string()
    .nullable()
    .required('กรุณาระบุเหตุผล')
    .max(100, 'กรุณาระบุเหตุผลไม่เกิน 100 ตัวอักษร'),
  sectionID: yup
    .mixed()
    .required('กรุณาเลือกรายการขั้นตอนสำหรับตรวจสอบใบสมัคร')
    .test(
      'is-valid-sectionID',
      'กรุณาเลือกรายการขั้นตอนสำหรับตรวจสอบใบสมัคร',
      (value) => {
        if (typeof value === 'string') {
          return value.trim() !== ''
        }
        if (typeof value === 'object' && 'value' in value) {
          return (value as { value: string }).value.trim() !== ''
        }
        return false
      }
    ),
  reasonForMA: yup.string().required('กรุณาระบุข้อความสำหรับผู้จัดการ'),
  reasonForProspect: yup.string().required('กรุณาระบุข้อความสำหรับผู้สมัคร'),
})
