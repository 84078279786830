import { Box, Typography } from '@mui/material'
import { useSelector, shallowEqual } from 'react-redux'
import { CustomCard } from '../../../../../../components/Layout/CustomCard'
import { SliceModel } from '../../../../../../redux/models'
import { themeColor } from '../../../../../../styles/themes/themeData'
import InfoRoundedIcon from '@mui/icons-material/InfoRounded'

export default function Reject() {
  const { isLoading } = useSelector(
    (state: SliceModel) => ({
      isLoading: state.prospect.detail.isLoading,
    }),
    shallowEqual
  )

  return (
    <CustomCard
      isLoading={isLoading}
      sx={{ height: '145px', alignItems: 'center', display: 'flex' }}
    >
      <Box display={'flex'} alignItems={'center'} gap={2}>
        <Box>
          <InfoRoundedIcon
            sx={{
              fontSize: '60px',
              p: 1,
              backgroundColor: '#F5F5F5',
              borderRadius: 3,
              color: themeColor.error.main,
            }}
          />
        </Box>
        <Box>
          <Typography variant="body1b">ไม่ผ่านการสมัคร</Typography>
          <Typography variant="body1" color={'text.gray'}>
            ผู้สมัครไม่มีคุณสมบัติ
          </Typography>
        </Box>
      </Box>
    </CustomCard>
  )
}
