import get from 'lodash/get'
import { enumStatus } from '../../../../../../components/CRUD/enum/status'

export function postBodyMutation(data: Record<string, string>) {
  const status = get(data, 'status', enumStatus.INACTIVE)
  // ตรวจสอบ sectionID และ topic
  const sectionID =
    typeof get(data, 'sectionID') === 'object'
      ? get(data, 'sectionID.value')
      : get(data, 'sectionID')

  const topic =
    typeof get(data, 'topic') === 'object'
      ? get(data, 'topic.value')
      : get(data, 'topic')

  const reasonForMA = get(data, 'reasonForMA')
  const reasonForProspect = get(data, 'reasonForProspect')
  const requireDetail = get(data, 'requireDetail', enumStatus.INACTIVE)

  return {
    uuid: get(data, 'uuid', undefined),
    id: get(data, 'id', ''),
    name: 'user test',
    text: get(data, 'text', ''),
    sectionID,
    topic,
    reasonForMA,
    reasonForProspect,
    requireDetail:
      requireDetail && requireDetail !== ''
        ? requireDetail
        : enumStatus.INACTIVE,
    status: status && status !== '' ? status : enumStatus.INACTIVE,
  }
}
