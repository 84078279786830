import { CustomInput } from '../../../../../../components/CRUD/components/CustomInput'
import CustomSwitchInLine from '../../../../../../components/CRUD/components/CustomSwitchInLine'
import Divider from '@mui/material/Divider'

export const BasicInfo = () => {
  return (
    <>
      <CustomInput
        required
        labelName="ประเภทของเหตุผลการปฎิเสธนัดสัมภาษณ์"
        fieldName="name"
        placeholder="ระบุประเภทของเหตุผลการปฎิเสธนัดสัมภาษณ์"
      />
      <CustomSwitchInLine
        fieldName="requireDetail"
        title="ต้องระบุรายละเอียดเพิ่มเติม (กรณีเหตุผลอื่นๆ)"
      />
      <CustomSwitchInLine fieldName="send" title="ส่งเรื่องต่อไปยังแอดมิน" />
      <CustomSwitchInLine fieldName="status" title="สถานะ" />
      <Divider />
    </>
  )
}
