import Modal from 'react-modal'
import {
  type Dispatch,
  memo,
  type SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import ErrorIcon from '@mui/icons-material/Error'
import MoneyOutlinedIcon from '@mui/icons-material/MoneyOutlined'
import useTheme from '@mui/material/styles/useTheme'
import CustomDropdown from '../../../../components/Input/Dropdown'
import { CustomTextArea } from '../../../../components/CRUD/components/CustomTextArea'
import { PAID, StatusEnum } from '../../../../constants/status'
import { useDispatch, useSelector } from '../../../../redux/store'
import { addMemoItem } from '../../slices/applicant-details'
import {
  selectCrudFormData,
  selectInitialProfile,
  selectProfileDetails,
  selectTableRows,
} from '../../../../redux/selector'
import type { MemoOptions } from '../model/memo'
import { openModal } from '../../slices/receive-payment'
import ReceivePayment from './components/ReceivePayment'
import _ from 'lodash'
import { setClearForm } from '../../../../components/CRUD/slices'
import { disabledConsiderationAll } from '../handler/disabledButton'
import { memoListTopic } from '../../../../constants/dc-admin/memoList'
import { getValueStr } from '../../../../utils/getValue'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { useCompare } from '../CompareTable/handler/useCompare'
import {
  setCompareFirstIndex,
  setCompareIsLoading,
  setCompareSecondIndex,
  setIsCompareItems,
} from '../CompareTable/slices'
import { useDelay } from '../../../../utils/useDelay'
import { fetchDataList } from '../../../../components/CRUD/handler/fetchDataList'
import { enumStatus } from '../../../../components/CRUD/enum/status'
import { initialConfig } from '../../../../components/CRUD/handler/initialConfig'
import { crudConfig } from '../../../MasterData/reason-request-memo/crudConfig'
import useLocalStorage from '@rehooks/local-storage'
import { permissionItem } from '../../../../constants/permission'

type Props = {
  isLastStep?: boolean
  titleStep: string
  setTab?: Dispatch<SetStateAction<number>>
}

Modal.setAppElement('#root')

const HeaderActions = memo(({ isLastStep }: Readonly<Props>) => {
  const theme = useTheme()

  const [isOpenModal, setIsOpenModal] = useState(false)
  const [user] = useLocalStorage('user')
  const uniqPermissions = _.get(user, 'uniqPermissions', [])

  const memoOptions = useSelector(selectTableRows) as MemoOptions[]
  const formData = useSelector(selectCrudFormData)
  const profileDetails = useSelector(selectProfileDetails)
  const initProfile = useSelector(selectInitialProfile)
  const compare = useCompare()

  const dispatch = useDispatch()

  const closeModal = () => {
    setIsOpenModal(false)

    document.body.style.overflow = 'auto'
  }

  const submitUpdatePayment = useCallback(() => {
    dispatch(openModal())
  }, [dispatch])

  const handleAddMemo = useCallback(() => {
    const {
      reasonRequestMemo: {
        uuid,
        text,
        reasonForMA,
        reasonForProspect,
        sectionID,
      },
      reasonTopic,
      details,
    } = formData

    dispatch(
      addMemoItem({
        uuid,
        reasonTopic: reasonTopic,
        reason: text,
        message: { manager: reasonForMA, prospect: reasonForProspect },
        titleStep: sectionID,
        details,
      })
    )

    closeModal()
  }, [dispatch, formData])

  const handleMemoClick = () => {
    setIsOpenModal(true)
    dispatch(setClearForm())
    // Lock scroll when modal is open
    document.body.style.overflow = 'hidden'
    fetchDataList(() => ({ status: [enumStatus.ACTIVE], name: '' }))
  }

  const handleStartCompare = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked
    dispatch(setIsCompareItems(checked))
    dispatch(setCompareIsLoading(true))
    dispatch(setCompareFirstIndex(0))
    dispatch(setCompareSecondIndex(0))
    await useDelay(300)
    dispatch(setCompareIsLoading(false))
  }
  useEffect(() => {
    initialConfig(crudConfig)
  }, [])
  const disabledAddMemoBtn = () => {
    if (
      _.isEmpty(formData?.reasonTopic) ||
      _.isEmpty(formData?.reasonRequestMemo)
    )
      return true

    const find = _.find(memoOptions, {
      uuid: formData?.reasonRequestMemo?.uuid,
    })
    if (
      find?.requireDetail === StatusEnum.ACTIVE &&
      _.isEmpty(formData?.details)
    ) {
      return true
    }
    return false
  }
  const isRequireDetail = () => {
    const find = _.find(memoOptions, {
      uuid: formData?.reasonRequestMemo?.uuid,
    })
    return find?.requireDetail === StatusEnum.ACTIVE
  }
  return (
    <>
      <Stack
        direction="row"
        spacing={!isLastStep ? 3 : 2}
        alignSelf="flex-end"
        justifyContent={'end'}
        mb={1}
      >
        <Button
          variant="outlined"
          startIcon={<ErrorIcon fontSize="small" />}
          color="error"
          size="medium"
          onClick={handleMemoClick}
          disabled={
            !_.includes(uniqPermissions, permissionItem.DC_ADMIN) ||
            disabledConsiderationAll(initProfile)
          }
        >
          ขอแก้ไขเอกสาร
        </Button>

        {isLastStep && (
          <Button
            variant="contained"
            color="success"
            size="medium"
            startIcon={
              <MoneyOutlinedIcon
                fontSize="small"
                sx={{ color: theme.palette.info.contrastText }}
              />
            }
            onClick={submitUpdatePayment}
            disabled={
              !_.includes(uniqPermissions, permissionItem.DC_ADMIN) ||
              profileDetails?.paymentStatus === PAID ||
              _.isNull(initProfile?.contractor) ||
              !_.isNull(profileDetails?.paymentStatus) ||
              disabledConsiderationAll(initProfile)
            }
          >
            <Typography
              variant="buttons"
              sx={{ color: theme.palette.info.contrastText }}
            >
              รับเงิน
            </Typography>
          </Button>
        )}
      </Stack>
      <Stack
        direction="row"
        spacing={!isLastStep ? 3 : 2}
        alignSelf="flex-end"
        justifyContent={'end'}
      >
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={compare.isCompare}
                onChange={handleStartCompare}
              />
            }
            label={<Typography variant="body1">เปรียบเทียบข้อมูล</Typography>}
          />
        </FormGroup>
      </Stack>
      <Modal
        isOpen={isOpenModal}
        onRequestClose={closeModal}
        contentLabel="Add memo modal"
        style={{
          overlay: {
            backgroundColor: `${theme.palette.common.black}BF`,
            zIndex: 1200,
          },
          content: {
            maxWidth: '678px',
            width: '100%',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: 0,
            backgroundColor: theme.palette.background.paper,
            borderRadius: '8px',
            boxShadow: `0px 9px 46px 8px ${theme.palette.common.black}1F,
                        0px 24px 38px 3px ${theme.palette.common.black}24,
                        0px 11px 15px -7px ${theme.palette.common.black}33`,
          },
        }}
      >
        <Box padding={2}>
          <Typography variant="h6">ขอแก้ไขเอกสาร</Typography>
        </Box>
        <Stack padding={2} pt={0} gap={2}>
          <Typography variant="body1b">ข้อมูลส่วนบุคคล</Typography>

          <CustomDropdown
            label="หัวข้อเหตุผล"
            required
            options={memoListTopic}
            isOptionEqualToValue={(option, select) =>
              option.name === select.name
            }
            getOptionLabel={(option: MemoOptions) => option.name}
            fullWidth
            placeholder="กรุณาเลือกเหตุผล"
            crud="reasonTopic"
            disableClearable
          />

          <CustomDropdown
            key={formData?.reasonTopic?.name}
            label="ข้อความ"
            required
            isOptionEqualToValue={(option, select) =>
              option.text === select.text
            }
            options={memoOptions.filter(
              (item) =>
                getValueStr(item, 'topic') ===
                getValueStr(formData, 'reasonTopic.name')
            )}
            getOptionLabel={(option: MemoOptions) => option.text} // * text = reason to request memo
            fullWidth
            placeholder="กรุณาเลือกข้อความ"
            crud="reasonRequestMemo"
            disableClearable
          />

          <Box>
            <Typography variant="body2" color="text.secondary">
              ข้อความ
            </Typography>

            <Box border={`1px solid ${theme.palette.text.silver}`}>
              <Stack
                direction="row"
                flexWrap="wrap"
                alignItems="center"
                bgcolor={theme.palette.background.blue}
                borderBottom={`1px solid ${theme.palette.text.silver}`}
              >
                <Box
                  flexBasis="50%"
                  padding={2}
                  borderRight={`1px solid ${theme.palette.text.silver}`}
                >
                  <Typography variant="subtitle2">
                    ข้อความสำหรับ ผู้จัดการ
                  </Typography>
                </Box>

                <Box flexBasis="50%" padding={2}>
                  <Typography variant="subtitle2">
                    ข้อความสำหรับ ผู้สมัคร
                  </Typography>
                </Box>
              </Stack>

              <Stack direction="row" flexWrap="wrap" alignItems="center">
                <Box
                  flexBasis="50%"
                  padding={2}
                  borderRight={`1px solid ${theme.palette.text.silver}`}
                >
                  <Typography variant="body2">
                    {formData?.reasonRequestMemo?.reasonForMA}
                  </Typography>
                </Box>
                <Box flexBasis="50%" padding={2}>
                  <Typography variant="body2">
                    {formData?.reasonRequestMemo?.reasonForProspect}
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </Box>

          <CustomTextArea
            labelName="รายละเอียดเพิ่มเติม"
            fieldName="details"
            placeholder="รายละเอียดเพิ่มเติม"
            required={isRequireDetail()}
          />

          <Stack direction="row" spacing={1.5} marginLeft="auto" paddingY={1}>
            <Button variant="outlined" onClick={closeModal}>
              ยกเลิก
            </Button>
            <Button
              variant="contained"
              onClick={handleAddMemo}
              disabled={disabledAddMemoBtn()}
            >
              เพิ่มลงโฟลเดอร์
            </Button>
          </Stack>
        </Stack>
      </Modal>
      <ReceivePayment fullFormUuid={String(initProfile?.fullformUuid)} />
    </>
  )
})

HeaderActions.displayName = 'HeaderActions'

export default HeaderActions
