import { callAPI } from '../../../utils/callAPI'
import { dispatch } from '../../../redux/store'
import type { CheckHistoryStatus } from '../applicant-details/model/background-review-options'
import {
  createSlice,
  createAsyncThunk,
  type PayloadAction,
} from '@reduxjs/toolkit'
// types
import type {
  FullFormDetails,
  InitProfile,
  CheckHistory,
  AttachmentMeta,
} from '../../../types/full-form'
import {
  ProcessResultApprovalStatus,
  type ApiStatus,
} from '../../../constants/status'
// utils
import {
  updateProcessStatus,
  getFullFormData,
  requestMemo,
  createNote,
  deleteNote,
  notes,
  updateAudit,
  initProfile,
  checkHistory,
  variousHistoryStatus,
  verifyBackground,
  updatePayment,
  updateConsideration,
  rejectProspect,
  updateLicenseAndAgentCode,
  sendToCCM,
  uploadResult,
  rejectByOIC,
  dataLicense,
  updateRequestConsideration,
  createRequestConsideration,
  updateAddContracter,
} from '../../../utils/apiPath'
import { UploadFieldName } from '../applicant-details/model/upload'
import { enumStatus } from '../../../components/CRUD/enum/status'
import _ from 'lodash'

type Note = Partial<{
  title: string
  contentId: number
  details: string
  attachDoc: AttachmentMeta[]
}>

export const applicationStatusStep = {
  DS_REVIEWED: 'รอดำเนินการ',
  DCV_ACCEPTED_REVIEW: 'กำลังตรวจสอบใบสมัคร/ประวัติ',
  DCV_REQUESTED_MEMO: 'รอการแก้ไข',
  DS_SAVED_FULL_FORM_MEMO: 'กำลังตรวจสอบใบสมัคร/ประวัติ',
  DS_SUBMITTED_FULL_FORM_MEMO: 'กำลังตรวจสอบใบสมัคร/ประวัติ',
  DS_REVIEWED_MEMO: 'กำลังตรวจสอบใบสมัคร/ประวัติ',
  DCV_PASSED_PROSPECT_HISTORY: 'ตรวจสอบสำเร็จ',
  DCV_REQUESTED_DESCISION: 'รอพิจารณา/ติดตามผล',
  DCV_UPDATED_DESCISION: 'ได้รับผลพิจารณาแล้ว',
  DCV_APPROVED_TO_BE_AGENT: 'ตรวจสอบสำเร็จ',
  DCV_REQUESTED_E_LICENCING: 'รอเลขใบอนุญาต',
  DCV_UPDATED_E_LICENCING_PASSED: 'รอออกรหัสกับ RCMS',
  DCV_REQUESTED_RCMS: 'รอออกรหัสกับ RCMS',
  DCV_UPDATED_AGENT_CODE: 'ออกชุดรหัสสำเร็จ',
  DCV_DROPPED: 'ถูกปฎิเสธ',
  DCV_SENT_MAIL_DESCISION_CCM: 'รอ CCM ตรวจสอบ',
  DCV_UPDATED_E_LICENCING_FAILED: 'ไม่ผ่านการออกใบอนุญาตกับ คปภ.',
  DCV_REQUESTED_DESCISION_CCM: 'รอส่ง CCM',
}

type MemoItem = {
  uuid: string
  reasonTopic: string
  reason: string
  message?: {
    manager: string
    prospect: string
  }
  details?: string
  titleStep: string
}

export type BackgroundReview = {
  variousName: string
  variousChannel: string
  status: CheckHistoryStatus
}

export type ApplicantDetails = {
  isLoading: boolean
  error: Error | string | null
  initProfile: InitProfile | null
  profileDetails: null | FullFormDetails
  backgroundReview: {
    verifyBackgroundByDC: boolean
    inviteCode: string
    managerData: null | {
      userCode: string
      prefixTH: string
      firstNameTH: string
      lastNameTH: string
    }
    variousHistory: BackgroundReview[]
    auditInfo: null | {
      id: number
      createdAt: string
      createdBy: string
      updatedAt: string
      updatedBy: string
      fullformUuid: string
      ramUuid: string
      ramName: string
      area: string
      level: string
      ga: string
      role: string
      tUnit: string
      eUnit: string
      hasLicense: boolean
      licenseNumber: string
      licenseStartDate: string
      licenseExpireDate: string
      formerAgentAXA: boolean
      licenseNumberAXA: string
      insuranceCompany: string
    }
  }
  notes: Note[]
  applicationStatusStep: (typeof applicationStatusStep)[keyof typeof applicationStatusStep]
  editHistory: {
    titleStep: string
    title: string
    input: string
    reason: string
    detail: string
    memoDate: string
    updatedDate: string
  }[]
  memoList: MemoItem[]
  relatedDocs: Omit<Note, 'details'>[]
  auditHistory: {
    titleStep: string
    reason: string
    details: string
    dataAccuracy: 'MEMO | CORRECT'
    auditor: string
    createdAt: string
  }[]
  titleStepComplete: string[]
  fixedFileBox: AttachmentMeta[]
  showFile: {
    isOpen: boolean
    file: AttachmentMeta | null | undefined
  }
}

const initialState: ApplicantDetails = {
  isLoading: false,
  error: null,
  initProfile: null,
  profileDetails: null,
  backgroundReview: {
    verifyBackgroundByDC: false,
    inviteCode: '',
    managerData: null,
    variousHistory: [],
    auditInfo: null,
  },
  notes: [],
  applicationStatusStep: applicationStatusStep.DS_REVIEWED,
  editHistory: [],
  memoList: [],
  relatedDocs: [],
  auditHistory: [],
  titleStepComplete: [],
  fixedFileBox: [],
  showFile: {
    isOpen: false,
    file: null,
  },
}

const slice = createSlice({
  name: 'applicantDetails',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
    },
    endLoading(state) {
      state.isLoading = false
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    // GET INITIAL PROFILE SUCCESS
    getInitialProfileSuccess(state, action: PayloadAction<InitProfile>) {
      state.isLoading = false

      state.initProfile = action.payload
    },

    // GET PROFILE DETAILS FROM FULL-FORM
    getProfileDetailsSuccess(state, action: PayloadAction<FullFormDetails>) {
      state.isLoading = false
      state.profileDetails = action.payload
    },
    getProfileDetailsDCMemo(state, action: PayloadAction<any[]>) {
      if (state.profileDetails) {
        state.profileDetails.dcMemo = action.payload
      }
    },

    updateProcessStatus(
      state,
      action: PayloadAction<keyof typeof applicationStatusStep>
    ) {
      state.isLoading = false

      if (state.initProfile) {
        state.initProfile.progressBarStatus = action.payload
      }
    },

    // GET RELATED DOCS FROM PROFILE DETAILS
    getRelatedDocsSuccess(
      state,
      action: PayloadAction<Omit<Note, 'details'>[]>
    ) {
      state.isLoading = false
      state.relatedDocs = action.payload
    },

    // SET SECTION ID IS CORRECT
    setTitleStepCorrectSuccess(
      state,
      action: PayloadAction<{
        considerationName: keyof FullFormDetails['consideration']
        status: FullFormDetails['consideration'][keyof FullFormDetails['consideration']]
      }>
    ) {
      const { considerationName, status } = action.payload

      state.isLoading = false

      if (state.profileDetails) {
        state.profileDetails.consideration = {
          ...state.profileDetails.consideration,
          [considerationName]: status,
        }
      }
    },

    // UPDATE PAYMENT STATUS
    updatePayment(state) {
      state.isLoading = false

      if (state.profileDetails) {
        state.profileDetails.paymentStatus = 'PAID'
      }
    },

    addMemoItem(state, action: PayloadAction<MemoItem>) {
      state.memoList = [...state.memoList, action.payload]
    },

    deleteMemoItem(state, action: PayloadAction<string>) {
      state.memoList = state.memoList.filter(
        (memo) => memo.uuid !== action.payload
      )
    },

    clearMemoList(state) {
      state.isLoading = false
      state.memoList = []
    },

    getRemarksSuccess(state, action: PayloadAction<ResponseNotes[]>) {
      state.isLoading = false
      // state.notes = action.payload
      state.notes = action.payload.map((note) => ({
        title: note.toppic ?? '-',
        contentId: note.contentId,
        details: note.detail,
        attachDoc: note.AttachmentMeta,
      }))
    },

    getBackgroundReviewSuccess(
      state,
      action: PayloadAction<ApplicantDetails['backgroundReview']>
    ) {
      state.isLoading = false
      state.backgroundReview = { ...action.payload }
    },

    updateVariousHistoryStatus(state, action: PayloadAction<BackgroundReview>) {
      state.isLoading = false
      state.backgroundReview = {
        ...state.backgroundReview,
        variousHistory: [
          ...state.backgroundReview.variousHistory,
          action.payload,
        ],
      }
    },
    updateVariousHistoryStatusIsLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload
    },
    verifyBackgroundByDC(state) {
      state.isLoading = false
      state.backgroundReview = {
        ...state.backgroundReview,
        verifyBackgroundByDC: true,
      }
    },

    getDataLicenseSuccess(
      state,
      action: PayloadAction<
        Pick<
          FullFormDetails['main'],
          | 'agentCode'
          | 'effectiveDate'
          | 'licenseNumber'
          | 'startLicenseDate'
          | 'expireLicenseDate'
        >
      >
    ) {
      state.isLoading = false

      if (state.profileDetails) {
        state.profileDetails.main = {
          ...state.profileDetails?.main,
          ...action.payload,
        }
      }
    },
    setFixedFileBox(state, action: PayloadAction<AttachmentMeta>) {
      const newArr = [...state.fixedFileBox, action.payload]
      state.fixedFileBox = _.uniqBy(newArr, { id: action.payload.id })
    },
    setClearFixedFileBox(state) {
      state.fixedFileBox = []
    },
    setShowFile(
      state,
      action: PayloadAction<{
        isOpen: boolean
        file: AttachmentMeta | null | undefined
      }>
    ) {
      state.showFile = action.payload
    },
  },
})

// Reducer
export default slice.reducer

// Actions
export const {
  hasError,
  startLoading,
  endLoading,
  addMemoItem,
  deleteMemoItem,
  clearMemoList,
  setFixedFileBox,
  setClearFixedFileBox,
  setShowFile,
  setTitleStepCorrectSuccess,
  getProfileDetailsDCMemo,
} = slice.actions

// ----------------------------------------------------------------------

export const getProfileDetails = createAsyncThunk<
  // Return type of the thunk
  void,
  // Thunk argument type
  { prospectId: string },
  // Thunk API type
  { rejectValue: string }
>(
  'applicantDetails/getProfileDetails',
  async ({ prospectId }, { signal, rejectWithValue }) => {
    dispatch(slice.actions.startLoading())

    try {
      const [profileDetails, initialProfile] = await Promise.all([
        callAPI<{
          status: ApiStatus
          result: FullFormDetails
        }>({
          url: getFullFormData(prospectId),
          method: 'GET',
          signal,
        }),
        callAPI<{ status: ApiStatus; result: InitProfile }>({
          url: initProfile(prospectId),
          method: 'GET',
          signal,
        }),
      ])

      dispatch(slice.actions.getProfileDetailsSuccess(profileDetails.result))
      dispatch(slice.actions.getInitialProfileSuccess(initialProfile.result))
    } catch (error) {
      console.error(getProfileDetails.name, JSON.stringify(error, null, 2))

      dispatch(slice.actions.hasError(error))

      if (error instanceof Error) {
        return rejectWithValue(error.message)
      }

      return rejectWithValue(JSON.stringify(error))
    }
  }
)

export const submitUpdateProcessStatus = createAsyncThunk<
  // Return type of the thunk
  void,
  // Thunk argument type
  { fullFormUuid: string; processStatus: string },
  // Thunk API type
  { rejectValue: string }
>(
  'applicantDetails/updateProcessStatus',
  async (payload, { signal, rejectWithValue }) => {
    dispatch(slice.actions.startLoading())

    try {
      const resData = await callAPI<{
        status: ApiStatus
        step: keyof typeof applicationStatusStep
        message: 'UPDATE SUCCESS'
      }>({
        url: updateProcessStatus,
        method: 'POST',
        body: payload,
        signal,
      })

      dispatch(slice.actions.updateProcessStatus(resData.step))
    } catch (error) {
      console.error(error)

      dispatch(slice.actions.hasError(error))

      if (error instanceof Error) {
        return rejectWithValue(error.message)
      }

      return rejectWithValue(JSON.stringify(error))
    }
  }
)

export const submitUpdateContractor = createAsyncThunk<
  // Return type of the thunk
  void,
  // Thunk argument type
  { fullFormUuid: string },
  // Thunk API type
  { rejectValue: string }
>(
  'applicantDetails/updateAddContracter',
  async (payload, { signal, rejectWithValue }) => {
    dispatch(slice.actions.startLoading())

    console.log(payload, '< check update >', updateAddContracter)
    try {
      const resData = await callAPI<{
        status: ApiStatus
        step: keyof typeof applicationStatusStep
        message: 'UPDATE SUCCESS'
      }>({
        url: updateAddContracter,
        method: 'POST',
        body: payload,
        signal,
      })

      dispatch(slice.actions.updateProcessStatus(resData.step))

      window.location.reload()
    } catch (error) {
      console.error(error)

      dispatch(slice.actions.hasError(error))

      if (error instanceof Error) {
        return rejectWithValue(error.message)
      }

      return rejectWithValue(JSON.stringify(error))
    }
  }
)

export const submitRequestReject = createAsyncThunk<
  // Return type of the thunk
  void,
  // Thunk argument type
  {
    fullFormUuid: string
    dropProspectByDCUuid: string
    reasonText: string
    detail: string
  },
  // Thunk API type
  { rejectValue: string }
>(
  'applicantDetails/updateProcessStatus',
  async (payload, { signal, rejectWithValue }) => {
    dispatch(slice.actions.startLoading())

    try {
      const resData = await callAPI({
        url: rejectProspect,
        method: 'POST',
        body: payload,
        signal,
      })

      dispatch(slice.actions.updateProcessStatus(resData.step))
    } catch (error) {
      console.error(error)

      dispatch(slice.actions.hasError(error))

      if (error instanceof Error) {
        return rejectWithValue(error.message)
      }

      return rejectWithValue(JSON.stringify(error))
    }
  }
)

export const submitRequestMemo = createAsyncThunk<
  // Return type of the thunk
  void,
  // Thunk argument type
  { fullFormUuid: string; memoList: MemoItem[] },
  // Thunk API type
  { rejectValue: string }
>(
  'applicantDetails/submitRequestMemo',
  async ({ fullFormUuid, memoList }, { signal, rejectWithValue }) => {
    dispatch(slice.actions.startLoading())

    try {
      await callAPI({
        url: requestMemo,
        method: 'POST',
        body: {
          fullFormUuid,
          dataMemo: memoList.map((memo) => ({
            uuid: memo.uuid,
            reason: memo.reason,
            reasonSection: memo.titleStep,
            reasonForMA: memo.message?.manager,
            reasonForProspect: memo.message?.prospect,
            detail: memo.details,
          })),
        },
        signal,
        onSuccess() {
          dispatch(slice.actions.clearMemoList())
        },
      })
    } catch (error) {
      console.error(submitRequestMemo.name, JSON.stringify(error, null, 2))

      dispatch(slice.actions.hasError(error))

      if (error instanceof Error) {
        return rejectWithValue(error.message)
      }

      return rejectWithValue(JSON.stringify(error))
    }
  }
)

export const submitCreateNote = createAsyncThunk<
  // Return type of the thunk
  void,
  // Thunk argument type
  {
    fullFormUuid: string
    data: {
      fullFormUuid: string
      fieldName?: string
      contentId: string // uuid ของ Record ที่ Upload
      toppic?: string
      detail?: string
      noted?: string
    }
  },
  // Thunk API type
  { rejectValue: string }
>(
  'applicantDetails/submitCreateNote',
  async (payload, { signal, rejectWithValue }) => {
    dispatch(slice.actions.startLoading())

    try {
      await callAPI({
        url: createNote,
        method: 'POST',
        body: payload,
        signal,
      })
    } catch (error) {
      console.error(JSON.stringify(error))

      dispatch(slice.actions.hasError(error))

      if (error instanceof Error) {
        return rejectWithValue(error.message)
      }

      return rejectWithValue(JSON.stringify(error))
    }
  }
)

export const submitDeleteNote = createAsyncThunk<
  // Return type of the thunk
  void,
  // Thunk argument type
  {
    fullFormUuid: string
    contentId: number
  },
  // Thunk API type
  { rejectValue: string }
>(
  'applicantDetails/submitDeleteNote',
  async (payload, { signal, rejectWithValue }) => {
    dispatch(slice.actions.startLoading())

    try {
      await callAPI({
        url: deleteNote,
        method: 'POST',
        body: payload,
        signal,
      })
    } catch (error) {
      console.error(JSON.stringify(error))

      dispatch(slice.actions.hasError(error))

      if (error instanceof Error) {
        return rejectWithValue(error.message)
      }

      return rejectWithValue(JSON.stringify(error))
    }
  }
)

type ResponseNotes = {
  status: keyof typeof enumStatus
  contentId: number
  toppic: string | null
  detail: string
  AttachmentMeta: AttachmentMeta[]
}

export const getNotes = createAsyncThunk<
  // Return type of the thunk
  void,
  // Thunk argument type
  string,
  // Thunk API type
  { rejectValue: string }
>(
  'applicantDetails/getRemarks',
  async (fullFormUuid, { signal, rejectWithValue }) => {
    dispatch(slice.actions.startLoading())

    try {
      const resData = await callAPI<{
        status: ApiStatus
        result: ResponseNotes[]
      }>({
        url: notes(fullFormUuid),
        method: 'GET',
        signal,
      })

      dispatch(slice.actions.getRemarksSuccess(resData.result))
    } catch (error) {
      console.error(JSON.stringify(error))
      dispatch(slice.actions.getRemarksSuccess([]))
      dispatch(slice.actions.hasError(error))

      if (error instanceof Error) {
        return rejectWithValue(error.message)
      }

      return rejectWithValue(JSON.stringify(error))
    }
  }
)

type UpdateAuditInfoPayload = {
  fullFormUuid: string
  ramUuid: string
  ramName: string
  area: string
  level: string
  ga: string
  role: string
  tUnit: string
  eUnit: string
  hasLicense: boolean
  licenseNumber: string
  licenseStartDate: string
  licenseExpireDate: string
  formerAgentAXA: boolean
  licenseNumberAXA: string
}

export const updateAuditInfo = createAsyncThunk<
  // Return type of the thunk
  void,
  // Thunk argument type
  UpdateAuditInfoPayload,
  // Thunk API type
  { rejectValue: string }
>(
  'applicantDetails/updateAuditInfo',
  async (payload, { signal, rejectWithValue }) => {
    dispatch(slice.actions.startLoading())

    try {
      await callAPI<{
        status: ApiStatus
        result: Omit<UpdateAuditInfoPayload, 'fullFormUuid'> & {
          id: number
          createdAt: string
          updatedAt: string
          createdBy: string
          updatedBy: string
          fullformUuid: string
        }
      }>({
        url: updateAudit,
        method: 'POST',
        body: payload,
        signal,
      })
      dispatch(slice.actions.endLoading())
    } catch (error) {
      console.error(error)

      dispatch(slice.actions.hasError(error))

      if (error instanceof Error) {
        return rejectWithValue(error.message)
      }

      return rejectWithValue(JSON.stringify(error))
    }
  }
)

export const updatePaymentStatus = createAsyncThunk<
  // Return type of the thunk
  void,
  // Thunk argument type
  string,
  // Thunk API type
  { rejectValue: string }
>(
  'applicantDetails/updatePaymentStatus',
  async (fullFormUuid, { signal, rejectWithValue }) => {
    dispatch(slice.actions.startLoading())

    try {
      await callAPI({
        url: updatePayment,
        method: 'POST',
        body: {
          fullFormUuid,
        },
        signal,
      })

      dispatch(slice.actions.updatePayment())
    } catch (error) {
      console.error(error)

      dispatch(slice.actions.hasError(error))

      if (error instanceof Error) {
        return rejectWithValue(error.message)
      }

      return rejectWithValue(JSON.stringify(error))
    }
  }
)

export const submitUpdateConsideration = createAsyncThunk<
  // Return type of the thunk
  void,
  // Thunk argument type
  { fullFormUuid: string; sectionName: string },
  // Thunk API type
  { rejectValue: string }
>(
  'applicantDetails/submitUpdateConsideration',
  async (payload, { signal, rejectWithValue }) => {
    dispatch(slice.actions.startLoading())

    try {
      const resData = await callAPI<{
        status: ApiStatus.SUCCESS
        result: {
          fullformUuid: string
          status: 'Pending' | 'Completed' | 'Rejected'
          sectionName: keyof FullFormDetails['consideration']
        }
        message: 'UPDATE SUCCESS'
      }>({
        url: updateConsideration,
        method: 'POST',
        body: payload,
        signal,
      })

      const { sectionName, status } = resData.result
      const enumSectionName = sectionName
        .toUpperCase()
        .replace('-', '') as typeof resData.result.sectionName // * FOR REUSE IN OTHER COMPONENTS, MUST BE CONVERT TO UPPERCASE AND TRIM DASH SYMBOL (-)

      dispatch(
        slice.actions.setTitleStepCorrectSuccess({
          considerationName: enumSectionName,
          status: status,
        })
      )
    } catch (error) {
      console.error(error)

      dispatch(slice.actions.hasError(error))

      if (error instanceof Error) {
        return rejectWithValue(error.message)
      }

      return rejectWithValue(JSON.stringify(error))
    }
  }
)

export const getCheckHistory = createAsyncThunk<
  // Return type of the thunk
  void,
  // Thunk argument type
  string,
  // Thunk API type
  { rejectValue: string }
>(
  'applicationDetails/checkHistory',
  async (fullFormUuid, { signal, rejectWithValue }) => {
    dispatch(slice.actions.startLoading())

    try {
      const { result } = await callAPI<{
        status: ApiStatus
        result: CheckHistory
      }>({
        url: checkHistory(fullFormUuid),
        method: 'GET',
        signal,
      })

      dispatch(
        slice.actions.getBackgroundReviewSuccess({
          verifyBackgroundByDC: result.verifyBackgroundByDC,
          inviteCode: result.inviteCode,
          managerData: result.managerData,
          variousHistory: result.variousHistory,
          auditInfo: result.dataAuditInfo,
        })
      )
    } catch (error) {
      console.error(error)

      dispatch(slice.actions.hasError)

      if (error instanceof Error) {
        return rejectWithValue(error.message)
      }

      return rejectWithValue(JSON.stringify(error))
    }
  }
)

export const updateVariousHistoryStatus = createAsyncThunk<
  // Return type of the thunk
  void,
  // Thunk argument type
  {
    fullFormUuid: string
    variousName: string
    newStatus: CheckHistoryStatus
    variousChannel: string
  },
  // Thunk API type
  { rejectValue: string }
>(
  'applicationDetails/variousHistoryStatus',
  async (payload, { signal, rejectWithValue }) => {
    dispatch(slice.actions.startLoading())

    try {
      await callAPI<{ status: ApiStatus }>({
        url: variousHistoryStatus,
        method: 'POST',
        data: payload,
        signal,
      })
      dispatch(slice.actions.updateVariousHistoryStatusIsLoading(false))
      // dispatch(
      //   slice.actions.updateVariousHistoryStatus({
      //     variousName: payload.variousName,
      //     variousChannel: payload.variousChannel,
      //     status: payload.newStatus,
      //   })
      // )
    } catch (error) {
      dispatch(slice.actions.hasError)

      if (error instanceof Error) {
        return rejectWithValue(error.message)
      }

      return rejectWithValue(JSON.stringify(error))
    }
  }
)

export const verifyBackgroundByDC = createAsyncThunk<
  // Return type of the thunk
  void,
  // Thunk argument type
  string,
  // Thunk api type
  { rejectValue: string }
>(
  'applicationDetails/verifyBackgroundByDC',
  async (fullFormUuid, { signal, rejectWithValue }) => {
    dispatch(slice.actions.startLoading())

    try {
      await callAPI<{ status: ApiStatus }>({
        url: verifyBackground,
        method: 'POST',
        body: {
          fullFormUuid,
        },
        signal,
      })

      dispatch(slice.actions.verifyBackgroundByDC())
      dispatch(
        slice.actions.updateProcessStatus(
          ProcessResultApprovalStatus.DCV_APPROVED_TO_BE_AGENT
        )
      )
    } catch (error) {
      dispatch(slice.actions.hasError(error))

      if (error instanceof Error) {
        return rejectWithValue(error.message)
      }

      return rejectWithValue(JSON.stringify(error))
    }
  }
)

type ResponseResult = {
  id: number
  createdAt: string
  createdBy: string
  updatedAt: string
  updatedBy: string
  uuid: string
  managerUuid: string
  prospectUuid: string
  invitedCode: string
  status: string
  paymentStatus: 'PAID' | null
  verifyApplicationByDC: boolean
  verifyBackgroundByDC: boolean
  appliedAt: string | null
  agentCode: string
  effectiveDate: null | string
  licenseNumber: null | string
  startLicenseDate: null | string
  expireLicenseDate: null | string
}

export const submitLicenseAgent = createAsyncThunk<
  // Return type of the thunk
  void,
  // Thunk argument type
  {
    fullFormUuid: string
    licenseNumber?: string
    startLicenseDate?: string
    expireLicenseDate?: string
    agentCode?: string
    effectiveDate?: string
  },
  // Thunk api type
  { rejectValue: string }
>(
  'applicationDetails/submitLicenseAgent',
  async (payload, { signal, rejectWithValue }) => {
    dispatch(slice.actions.startLoading())

    try {
      await callAPI<{
        status: ApiStatus
        message: string
        result: ResponseResult
      }>({
        url: updateLicenseAndAgentCode,
        method: 'POST',
        body: payload,
        signal,
      })

      dispatch(
        slice.actions.updateProcessStatus(
          payload.agentCode
            ? ProcessResultApprovalStatus.DCV_UPDATED_AGENT_CODE
            : ProcessResultApprovalStatus.DCV_UPDATED_E_LICENCING_PASSED
        )
      )
    } catch (error) {
      dispatch(slice.actions.hasError(error))

      if (error instanceof Error) {
        return rejectWithValue(error.message)
      }

      return rejectWithValue(JSON.stringify(error))
    }
  }
)

export const submitSendToCCM = createAsyncThunk<
  // Return type of the thunk
  void,
  // Thunk argument type
  string[],
  // Thunk api type
  { rejectValue: string }
>(
  'applicationDetails/sendToCCM',
  async (fullFormUuidList, { signal, rejectWithValue }) => {
    dispatch(slice.actions.startLoading())

    try {
      callAPI({
        url: sendToCCM,
        method: 'POST',
        body: {
          fullFormUuid: [...fullFormUuidList],
        },
        signal,
      })

      dispatch(
        slice.actions.updateProcessStatus(
          ProcessResultApprovalStatus.DCV_UPDATED_E_LICENCING_FAILED
        )
      )
    } catch (error) {
      dispatch(slice.actions.hasError(error))

      if (error instanceof Error) {
        return rejectWithValue(error.message)
      }

      return rejectWithValue(JSON.stringify(error))
    }
  }
)

export const submitUploadResult = createAsyncThunk<
  // Return type of the thunk
  void,
  // Thunk argument type
  { fieldName: UploadFieldName; base64: string },
  // Thunk api type
  { rejectValue: string }
>(
  'applicationDetails/sendToCCM',
  async (payload, { signal, rejectWithValue }) => {
    dispatch(slice.actions.startLoading())

    try {
      callAPI({
        url: uploadResult,
        method: 'POST',
        body: payload,
        signal,
      })

      dispatch(
        slice.actions.updateProcessStatus(
          ProcessResultApprovalStatus.DCV_REQUESTED_DESCISION
        )
      )
    } catch (error) {
      dispatch(slice.actions.hasError(error))

      if (error instanceof Error) {
        return rejectWithValue(error.message)
      }

      return rejectWithValue(JSON.stringify(error))
    }
  }
)

export const submitRejectByIOC = createAsyncThunk<
  // Return type of the thunk
  void,
  // Thunk argument type
  string,
  // Thunk api type
  { rejectValue: string }
>(
  'applicationDetails/rejectByIOC',
  async (fullFormUuid, { signal, rejectWithValue }) => {
    dispatch(slice.actions.startLoading())

    try {
      await callAPI<{
        status: ApiStatus
        message: string
        result: ResponseResult
      }>({
        url: rejectByOIC,
        method: 'POST',
        body: { fullFormUuid },
        signal,
      })

      dispatch(
        slice.actions.updateProcessStatus(
          ProcessResultApprovalStatus.DCV_UPDATED_E_LICENCING_FAILED
        )
      )
    } catch (error) {
      dispatch(slice.actions.hasError(error))

      if (error instanceof Error) {
        return rejectWithValue(error.message)
      }

      return rejectWithValue(JSON.stringify(error))
    }
  }
)

export const getDataLicense = createAsyncThunk<
  // Return type of the thunk
  void,
  // Thunk argument type
  string,
  // Thunk api type
  { rejectValue: string }
>(
  'applicationDetails/getDataLicense',
  async (fullFormUuid, { signal, rejectWithValue }) => {
    dispatch(slice.actions.startLoading())

    try {
      const resData = await callAPI<{
        status: ApiStatus
        result: {
          fullFormUuid: string
          main: Omit<FullFormDetails['main'], 'attachmentMetas'>
        }
      }>({
        url: dataLicense(fullFormUuid),
        method: 'GET',
        signal,
      })

      const {
        agentCode,
        effectiveDate,
        licenseNumber,
        startLicenseDate,
        expireLicenseDate,
      } = resData.result.main

      dispatch(
        slice.actions.getDataLicenseSuccess({
          agentCode,
          effectiveDate,
          licenseNumber,
          startLicenseDate,
          expireLicenseDate,
        })
      )
    } catch (error) {
      dispatch(slice.actions.hasError(error))

      if (error instanceof Error) {
        return rejectWithValue(error.message)
      }

      return rejectWithValue(JSON.stringify(error))
    }
  }
)

export enum ActionByEnum {
  considerationSRAM = 'SRAM',
  considerationRAM = 'RAM',
  considerationCADTO = 'CADTO',
  considerationCDO = 'CDO',
}

export const submitUpdateRequestConsideration = createAsyncThunk<
  // Return type of the thunk
  void,
  // Thunk argument type
  {
    fullformUuid: string
    result: boolean
    actionBy: ActionByEnum
  },
  // Thunk api type
  { rejectValue: string }
>(
  'applicationDetails/submitUpdateRequestConsideration',
  async (payload, { signal, rejectWithValue }) => {
    dispatch(slice.actions.startLoading())

    try {
      await callAPI({
        url: updateRequestConsideration,
        method: 'POST',
        body: payload,
        signal,
      })

      dispatch(
        slice.actions.updateProcessStatus(
          payload.actionBy.includes('CCM')
            ? ProcessResultApprovalStatus.DCV_REQUESTED_DESCISION_CCM
            : ProcessResultApprovalStatus.DCV_REQUESTED_DESCISION
        )
      )
    } catch (error) {
      dispatch(slice.actions.hasError(error))

      if (error instanceof Error) {
        return rejectWithValue(error.message)
      }

      return rejectWithValue(JSON.stringify(error))
    }
  }
)

export const submitCreateRequestConsideration = createAsyncThunk<
  // Return type of the thunk
  void,
  // Thunk argument type
  {
    fullFormUuid: string
    hasSendCCM?: boolean
    CCMUuid?: string
    hasSendRam?: boolean
    RamUuid?: string
    hasSendSRam?: boolean
    SRamUuid?: string
    hasSendCADTO?: boolean
    CADTOUuid?: string
    hasSendCDO?: boolean
    CDOUuid?: string
  },
  // Thunk api type
  { rejectValue: string }
>(
  'applicationDetails/submitCreateRequestConsideration',
  async (payload, { signal, rejectWithValue }) => {
    dispatch(slice.actions.startLoading())

    try {
      await callAPI({
        url: createRequestConsideration,
        method: 'POST',
        body: payload,
        signal,
      })

      dispatch(
        slice.actions.updateProcessStatus(
          payload.hasSendCCM
            ? ProcessResultApprovalStatus.DCV_REQUESTED_DESCISION_CCM
            : ProcessResultApprovalStatus.DCV_REQUESTED_DESCISION
        )
      )
    } catch (error) {
      dispatch(slice.actions.hasError(error))

      if (error instanceof Error) {
        return rejectWithValue(error.message)
      }

      return rejectWithValue(JSON.stringify(error))
    }
  }
)
