export const filterStatusItems = [
  {
    label: 'รอมอบหมายผู้จัดการ',
    name: 'waitingForReAssignChecked',
    status: 'DL_SUCCESSFULLY_SENT_ASSIGN_RULE',
  },
  {
    label: 'รอนัดหมาย',
    name: 'submittiedShortFormChecked',
    status: 'DL_ASSIGNED_MA',
  },
  {
    label: 'ระหว่างรอสัมภาษณ์',
    name: 'managerAcceptedInterviewChecked',
    status: 'I_ACCEPTED_INTERVIEW',
  },
  {
    label: 'รอยืนยันตัวตน',
    name: 'submittiedShortFormInactiveChecked',
    status: 'DL_SUBMITTED_SHORT_FORM',
  },
  {
    label: 'ไม่ผ่านการเป็นตัวแทน',
    name: 'droppedChecked',
    status: 'DL_DROPPED',
  },
  // {
  //   label: 'ถูกมอบหมายผู้จัดการคนใหม่',
  //   name: 'changedManagerChecked',
  //   status: 'DL_CHANGED_MA',
  // },
  {
    label: 'ปฏิเสธการนัดสัมภาษณ์',
    name: 'declinedInterviewChecked',
    status: 'I_DECLINED_INTERVIEW',
  },
  {
    label: 'ไม่ผ่านการสัมภาษณ์',
    name: 'failedInterviewChecked',
    status: 'I_FAILED_INTERVIEW',
  },
  {
    label: 'โดนปฎิเสธเกิน 2 ครั้ง',
    name: 'failedOutOfLimitChecked',
    status: 'I_FAILED_OUT_OF_LIMIT',
  },
  {
    label: 'ไม่มีการดำเนินงานจากผู้จัดการ',
    name: 'timeoutChecked',
    status: 'I_TIME_OUT_OF_LIMIT',
  },
  {
    label: 'รอกรอกฟอร์ม',
    name: 'passedInterviewChecked',
    status: 'I_PASSED_INTERVIEW',
  },
  {
    label: 'เริ่มกรอกฟอร์มแบบเต็ม',
    name: 'savedFullFormChecked',
    status: 'DS_SAVED_FULL_FORM',
  },
  {
    label: 'ส่งฟอร์มแบบเต็ม',
    name: 'submittedFullFormChecked',
    status: 'DS_SUBMITTED_FULL_FORM',
  },
  {
    label: 'ผู้จัดการตรวจสอบแล้ว',
    name: 'reviewedChecked',
    status: 'DS_REVIEWED',
  },
  {
    label: 'DC เริ่มตรวจสอบแล้ว',
    name: 'dcAcceptedReviewChecked',
    status: 'DCV_ACCEPTED_REVIEW',
  },
  {
    label: 'รอการแก้ไข',
    name: 'dcRequestedMemoChecked',
    status: 'DCV_REQUESTED_MEMO',
  },
  // {
  //   label: `เริ่มแก้ไขฟอร์มแบบเต็ม`,
  //   name: 'savedFullFormMemoChecked',
  //   status: 'DS_SAVED_FULL_FORM_MEMO',
  // },
  {
    label: 'ส่งฟอร์มแบบเต็มเพิ่มเติมสำเร็จ',
    name: 'submittedFullFormMemoChecked',
    status: 'DS_SUBMITTED_FULL_FORM_MEMO',
  },
  {
    label: 'กำลังตรวจสอบใบสมัคร/ประวัติ',
    name: 'reviewedMemoChecked',
    status: 'DS_REVIEWED_MEMO',
  },
  // {
  //   label: 'DC ตรวจเอกสารผ่านแล้ว',
  //   name: 'dcPassedProspectHistoryChecked',
  //   status: 'DCV_PASSED_PROSPECT_HISTORY',
  // },
  {
    label: 'รอส่ง CCM',
    name: 'dcRequestedDecisionCcmChecked',
    status: 'DCV_REQUESTED_DESCISION_CCM',
  },
  {
    label: 'รอ CCM ตรวจสอบ',
    name: 'dcSentMailDecisionCcmChecked',
    status: 'DCV_SENT_MAIL_DESCISION_CCM',
  },
  {
    label: 'รอพิจารณา/ติดตามผล',
    name: 'dcRequestedDecisionChecked',
    status: 'DCV_REQUESTED_DESCISION',
  },
  {
    label: 'ได้รับผลพิจารณา',
    name: 'dcUpdatedDecisionChecked',
    status: 'DCV_UPDATED_DESCISION',
  },
  // {
  //   label: 'DC อนุมัติการเป็นตัวแทน',
  //   name: 'dcApprovedToBeAgentChecked',
  //   status: 'DCV_APPROVED_TO_BE_AGENT',
  // },
  // {
  //   label: 'DC อัปเดทผล OIC แล้ว',
  //   name: 'dcUpdatedELicencingChecked',
  //   status: 'DCV_UPDATED_E_LICENCING',
  // },
  {
    label: 'รอเลขใบอนุญาต',
    name: 'dcRequestedELicencingChecked',
    status: 'DCV_REQUESTED_E_LICENCING',
  },
  {
    label: 'ได้รับเลขใบอนุญาต',
    name: 'dcUpdatedELicencingPassedChecked',
    status: 'DCV_UPDATED_E_LICENCING_PASSED',
  },
  {
    label: 'ไม่ผ่านการออกใบอนุญาตกับ คปภ.',
    name: 'dcUpdatedELicencingFailedChecked',
    status: 'DCV_UPDATED_E_LICENCING_FAILED',
  },
  {
    label: 'รอออกรหัสกับ RCMS',
    name: 'dcRequestedRcmsChecked',
    status: 'DCV_REQUESTED_RCMS',
  },
  // {
  //   label: 'ผ่านพิจารณา',
  //   name: 'dcUpdatedDecisionPassedChecked',
  //   status: 'DCV_UPDATED_DESCISION_PASSED',
  // },
  // {
  //   label: 'ได้รับผลพิจารณา',
  //   name: 'dcUpdatedDecisionCompletedChecked',
  //   status: 'DCV_UPDATED_DESCISION_COMPLETED',
  // },
  // {
  //   label: 'ไม่ผ่านพิจารณา',
  //   name: 'dcUpdatedDecisionFailedChecked',
  //   status: 'DCV_UPDATED_DESCISION_FAILED',
  // },
  {
    label: 'ถูกปฎิเสธ',
    name: 'dcDroppedChecked',
    status: 'DCV_DROPPED',
  },
  {
    label: 'เป็นตัวแทนเรียบร้อย',
    name: 'dcUpdatedAgentCodeChecked',
    status: 'DCV_UPDATED_AGENT_CODE',
  },
]

export const filterItems = [
  {
    label: 'ชื่อผู้สมัคร',
    nameCheckBox: 'nameChecked',
    nameInput: 'name',
    type: 'text',
  },
  {
    label: 'ช่องทาง',
    nameCheckBox: 'channelChecked',
    nameInput: 'channel',
    type: 'checkbox',
    list: [
      {
        label: 'Direct',
        name: 'directChecked',
        status: 'DIRECT',
      },
      {
        label: 'Referral',
        name: 'referralChecked',
        status: 'REFERRAL',
      },
    ],
  },
  {
    label: 'ชื่อจังหวัด',
    nameCheckBox: 'workProvinceChecked',
    nameInput: 'workProvince',
    type: 'text',
  },
  {
    label: 'เลขที่ตัวแทนผู้จัดการ',
    nameCheckBox: 'managerCodeChecked',
    nameInput: 'managerCode',
    type: 'text',
  },
  {
    label: 'ชื่อผู้จัดการ',
    nameCheckBox: 'managerNameChecked',
    nameInput: 'managerName',
    type: 'text',
  },
  {
    label: 'วันที่สมัคร',
    placeholder: 'เลือก ระยะวันที่สมัคร',
    nameCheckBox: 'dateChecked',
    nameInput: 'date',
    dateStateStart: 'startDate',
    dateStateFinish: 'endDate',
    dateStateDisplay: 'dateDisplayDate',
    type: 'daterange',
  },
  {
    label: 'สถานะการสมัคร',
    nameCheckBox: 'prospectStatusChecked',
    nameInput: 'prospectStatus',
    type: 'checkbox',
    list: filterStatusItems,
  },
  {
    label: 'สถานะการใช้งานของผู้สมัคร',
    nameCheckBox: 'loginStatusChecked',
    nameInput: 'loginStatus',
    type: 'checkbox',
    list: [
      {
        label: 'เคยเข้าสู่ระบบ',
        name: 'statusActiveChecked',
        status: 'ACTIVE',
      },
      {
        label: 'ไม่เคยเข้าสู่ระบบ',
        name: 'statusInactiveChecked',
        status: 'INACTIVE',
      },
    ],
  },
  {
    label: 'สถานะผู้สมัคร',
    nameCheckBox: 'userStatusChecked',
    nameInput: 'userStatus',
    type: 'checkbox',
    list: [
      {
        label: 'ใช้งาน',
        name: 'statusActiveChecked',
        status: 'ACTIVE',
      },
      {
        label: 'ไม่ใช้งาน',
        name: 'statusInactiveChecked',
        status: 'INACTIVE',
      },
      {
        label: 'ลบ',
        name: 'statusDeletedChecked',
        status: 'DELETED',
      },
    ],
  },
]
